























































































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import ImageInput from "@/components/ImageInput.vue";
import TagColorInput from "@/components/TagColorInput.vue";
import TagColorDisplay from "@/components/TagColorDisplay.vue";

export default Vue.extend({
  components: { ImageInput, TagColorInput, TagColorDisplay },
  data() {
    return {
      util,
      userManager,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: this.$t("PACKAGE_BATCH_ID"), value: "package_batch_id" },
        {
          text: this.$t("USER_NAME"),
          value: "sale_name",
          sortable: false,
        },
        { text: this.$t("NAME"), value: "name" },
        { text: this.$t("PACKAGE_COUNT"), value: "count" },
        { text: this.$t("WEIGHT"), value: "weight" },
        { text: this.$t("VOLUME"), value: "volume" },
        { text: this.$t("INTERIOR_COST"), value: "interior_cost" },
        { text: this.$t("PACKAGE_METHOD"), value: "package_method" },
        { text: this.$t("NOTE"), value: "note" },
        { text: "Đã dán", value: "pasted" },
        { text: this.$t("IMAGE"), value: "images" },
        { text: this.$t("STORE_TIME"), value: "store_time" },
        { text: "Màu tag", value: "tag_color" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      item: {},
      options: {},
      search: "",
      searchTagColor: null,
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {
        package_id: "",
        package_batch_id: "",
        name: "",
        count: 0,
        weight: 0,
        volume: 0,
        interior_cost: 0,
        images: [],
      },
      defaultItem: {
        package_id: "",
        package_batch_id: "",
        name: "",
        count: 0,
        weight: 0,
        volume: 0,
        interior_cost: 0,
        images: [],
      },
      availableStatusMap: {
        dk: this.$t("STATUS_DK"),
        x: this.$t("STATUS_X"),
        ck: this.$t("STATUS_CK"),
        vn: this.$t("STATUS_VN"),
      },
      urlFunc(item) {
        return Vue.filter("packageImageUrl")(item);
      },
    };
  },
  computed: {
    formTitle() {
      return this.$t("ADD_PACKAGE_ID");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      this.item = await apiClient.truckGet(this.$route.params.id);
      const { items, count } = await apiClient.packageGetAll({
        options: this.options,
        filters: [
          {
            key: "truck_id",
            operator: "equal_to",
            value: this.$route.params.id,
          },
          {
            key: "package_id",
            operator: "match",
            value: this.search,
          },
          {
            key: "tag_color",
            operator: "equal_to",
            value: this.searchTagColor,
          },
        ],
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      if (await apiClient.truckRemovePackage(this.item.id, this.editedItem)) {
        await this.initialize();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      if (this.editedItem.id) {
        result = await apiClient.packageUpdate(this.editedItem, [
          "package_id",
          "package_batch_id",
          "name",
          "count",
          "weight",
          "volume",
          "interior_cost",
          "package_method",
          "note",
          "images",
        ]);
      } else {
        result = await apiClient.truckAddPackage(
          this.item.id,
          this.editedItem.package_id
        );
      }
      if (result) {
        this.close();
        this.initialize();
      }
    },
    async submitLoad() {
      if (await apiClient.truckSubmitLoad(this.item.id)) {
        this.$router.push("/app/trucks");
      }
    },
    async updatePasted(item) {
      await apiClient.packageUpdate({
        id: item.id,
        pasted: true,
      });
      await this.initialize();
    },
  },
});
